html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

.drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}

.drag-box-message-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(68, 67, 67);
    border: solid #F96816;
    color: white;
    font-size: 15px;
    padding: 2px;
    width: 200px;
}

.drag-box-target-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BEDCFA;
    border: solid #F96816;
}

.drag-box-source-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    border: dashed black 3px;
    font-size: 15px;
}

.editor {
    width: 100%;
    height: 100%;
}

.editor-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.treegrid {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@keyframes fade-out-in {
    0% {
        opacity: 0
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.handle:hover {
    fill-opacity: 1;
    fill: white;
    stroke-width: 1;
    stroke: gray;
    cursor: ew-resize;
}

.handle {
    fill-opacity: 0;
}

div.editor-header p {
    font-size: 2em;
    text-align: left;
}

.editor-header [class^=col-] {
    padding-top: 5px;
    padding-bottom: 5px;
}

.editor-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.editor-title {
    flex: none;
    margin: 5px;
}

.editor-menu {
    flex: none;
    margin: 5px;
}

.perfbar {
    flex: none;
}

#app-container {
    height: 100%;
}

.editorDiv {
    height: 100%;
}

.gridDiv {
    height: 100%;
}

.emptyViewDiv {
    height: 100%;
    width: 100%;
}

#middle-pane {
    background-color: rgba(60, 70, 80, 0.10);
}

#bottom-pane {
    background-color: rgba(60, 70, 80, 0.15);
}

#left-pane, #center-pane, #right-pane {
    background-color: rgba(60, 70, 80, 0.05);
}

.pane-content {
    padding: 0 10px;
}

p {
    overflow: auto;
    position: relative;
}

body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: rgb(248, 248, 248)
}

#toolbox {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0.5em;
    margin-left: 1em;
    border: 2px solid #EEEEEE;
    border-radius: 5px;
    padding: 1em;
    z-index: 5;
}

#toolbox input {
    width: 30px;
    opacity: 0.4;
}

#toolbox input:hover {
    opacity: 1;
    cursor: pointer;
}

#hidden-file-upload {
    display: none;
}

#download-input {
    margin: 0 0.5em;
}

marker {
    fill: #333;
}

g.gnode {
    stroke: #333;
    stroke-width: 2;
}

g.gnode text {
    stroke-width: 0;
}

g.gattribute {
    stroke: #333;
    stroke-width: 2;
}

g.gattribute text {
    font-size: 10px;
    stroke-width: 0;
}

g.gtable {
    fill: darkblue;
}

g.gtable text {
    fill: white;
}

g.gattribute-header {
    fill: darkblue;
}

g.gattribute-header text {
    fill: white;
}

path.link {
    fill: none;
    stroke: #333;
    stroke-width: 1px;
    cursor: default;
}

path.link:hover {
    stroke: rgb(94, 196, 204);
}

g.connect-node rect {
    fill: #BEFFFF;
}

path.link.hidden {
    stroke-width: 0;
}

path.link.selected {
    stroke: rgb(229, 172, 247);
}

.dragrect {
    border: dashed 2px black;
    position: fixed;
    pointer-events: none;
    background: rgba(0, 0, 0, .3);
}

html, body {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

h2, .h2 {
    color: white;
}

h4, .h4 {
    font-weight: normal;
}

.dropdown-menu.pull-right {
    width: 100%;
    border-radius: 0;
    margin-top: -1px;
    border: 1px solid #74859c;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
}


.dropdown-menu.pull-right::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0;
    background-color: #fff;
}

.dropdown-menu.pull-right::-webkit-scrollbar {
    width: 27px;
    background-color: #fff;
}

.dropdown-menu.pull-right::-webkit-scrollbar-thumb {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #e9edf3;
}

#range-slider-1 {
    background-color: #ccdaff;
    width: 176px;
    display: inline-block;
    border-radius: 20px;
    height: 12px;
    margin-top: 10px;
}

#range-slider-2 {
    background-color: #ccdaff;
    width: 176px;
    display: inline-block;
    border-radius: 20px;
    height: 12px;
    margin-top: 10px;
}

.ui-slider .ui-slider-handle {
    border-radius: 5px;
    height: 30px;
    width: 10px;
    background-color: #c1c1c1;
    margin-top: -5px;
    margin-left: 0;
}

.range-wrapper {
    margin: 10px 0;
    position: relative;
    display: inline-block;
}

.range-wrapper > div {
    position: relative;
    top: 6px;
}

@media screen and (max-width: 1510px) {
    .sidebar .sidebar-head .version {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 100%;
    }
}

#metusrange {
    display: inline;
    margin-right: 5px;
    margin-top: 5px;
}

.editor-header button {
    margin-right: 5px;
    margin-top: 0;
}

.k-panelbar {
    max-width: 400px;
    margin: 0 auto;
}

.leftmenu-open .k-panelbar {
    -webkit-transform: translateX(340px);
    transform: translateX(400px);

}

.leftmenu-closed {

}

.leftmenu .k-panelbar {
    transition-duration: .2s;
    position: absolute;
    z-index: 1;
    left: -400px;
    width: 100%;
}

.halflings {
    position: relative;
    top: 1px;
    display: inline-block;
    /*noinspection CssNoGenericFontName*/
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    color: white;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#btn-leftmenu-toggle {
    position: absolute;
    top: -1.4em;
    width: inherit;
    z-index: 1;
    background-color: #fafafa;
    color: rgb(55, 75, 123);
    font-size: 1.3em;
    border: 0;
    padding: 0 5px 0 5px;
    border-radius: 1px;
    margin: 0;
}

.leftmenu {
    position: relative;
    margin-top: 4em;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Light Italic'), url('../fonts/MyriadPro-LightIt.woff2') format('woff2');
}

#loginform {
    position: fixed;
    top: calc(100% / 2 - 10em);
    left: calc(100% / 2 - 15em);
    max-width: 450px;
    z-index: 2000;
    background-color: rgb(55, 75, 123);
    color: white;
    border: 3px solid;
    padding: 20px 20px 20px 20px;
}

#loginform input {
    color: black;
}

#loginform + div {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.2;
    z-index: 1000;
}

.dnd-isover {
    background-color: rgb(220, 227, 237);
}

.context-menu {
    z-index: 200;
}

.react-mosaic-metus-app {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    background-color: rgba(0,0,0,0.8);
}

.react-mosaic-metus-app .mosaic-root {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.react-mosaic-metus-app .mosaic-root .mosaic-tile {
    margin: 1px;
}

.react-mosaic-metus-app > .mosaic {
    height: calc(100% - 0px);
}

.mosaic:not(.mosaic-blueprint-theme) .mosaic-window-controls > .mosaic-default-control.pt-icon-cross:before {
    content: "\2717";
}

body {
    user-select: none;
    -webkit-user-select: none;
}

.metus-window {
    height: 100%;
    padding: 0;
    justify-content: center;
    display: flex;
}

.metusContextMenu {
    z-index: 100;
}

.clickableAreaFiller {
    fill: transparent;
    stroke-width: 0;
}

.metusTooltip {
    background-color: white !important;
    border: solid black 1px !important;
    color: black !important;
    max-width: 200px;
}

.metus-mosaic-window {
    height: 100%;
}
